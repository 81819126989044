import {EventsMgr} from "/@lib@/commons/events.js";
import {IEndPoint, IO, IResponse, isEndPointErrorHookable} from "/@lib@/commons/io/io.js";
import {CDM} from "/@lib@/commons/utils/cdm.js";
import {BasicUniverse, IWsExecFrame} from "/@lib@/core/universe.js";
import {IAuthenticatedEnv, IRemoteAuth} from "/@lib@/commons/registry.js";
import {JUserRoles} from "/@lib@/commons/roles.js";


export enum EUserType {
	user = 'user',
	group = 'group'
}

interface JUserBase extends JUserRoles {
	account?: string
	userType?: EUserType
	nickNames?: string[]
	lastName?: string
	firstName?: string
	groupName?: string
	email?: string
	categ?: string
	groups?: string[]
	pwdEndDt?: number
	authMethod?: string
	disabledEndDt?: number
	isUnknown?: boolean
}

/** Props d'un user (accessibles en lecture). */
export interface JUser extends JUserBase {
	isAnonymous?: boolean
	isSuperAdmin?: boolean
	isDisabled?: boolean
	isReadOnly?: boolean
	isHidden?: boolean
	flattenedGroups?: string[]
	/** Date de dernière modif du password. */
	pwdDt?: number
	pwdEndDt?: number
	disabledEndDt?: number
}

/** Props modifiables d'un user. */
export interface JUserUpdate extends JUserBase {
	password?: string
}

/** Liste d'utilisateurs */
export interface JUsersSet {
	userList?: JUser[]
	more?: boolean
}

/** Filtre sur le caractère "disabled" d'un user
 * {@see eu.scenari.commons.user.IUsersBrowser.EUserDisabledFilters}*/
export enum EUserDisabledFilters {
	enabled = 'enabled',
	enabledPermanent = 'enabledPermanent',
	NotEnabledPermanent = 'NotEnabledPermanent',
	enabledTemporary = 'enabledTemporary',
	NotEnabledTemporary = 'NotEnabledTemporary',
	disabled = 'disabled',
	disabledPermanent = 'disabledPermanent',
	NotDisabledPermanent = 'NotDisabledPermanent',
	disabledTemporary = 'disabledTemporary',
	NotDisabledTemporary = 'NotDisabledTemporary',
}

/** type représentant un simple compte utilisateur **/
export type account = string;

type IAuthEvents = {

	/** A diffuser avant toute action de logout explicite demandé par le user de cette page. */
	beforeLogout: (user: JUser) => void | 'stop' | Promise<void | 'stop'>

	/** A diffuser après toute action de logout explicite demandé par le user de cette page (avant l'event userChanged). */
	afterLogout: () => void

	/**
	 * Notifie à chaque constat d'un changement de user (login, logout, déconnexion par le server).
	 * @param  newUser : null si deconnexion même si retour au user anonymous par défaut.
	 */
	loggedUserChanged: (oldUser: JUser, newUser: JUser) => void | Promise<void>

	/**
	 * Notifie que les propriétés de ce user ont été modifiées.
	 */
	userUpdated: (account: string, props?: JUser) => void
}

export interface OUserSrvBaseConfig {
	usersAspects?: EUserAspects[]
}

/**
 * Fonctions de base d'un serveur d'accès au user (courant, ou liste de users)
 */
export class UserSrvBase {
	public config: OUserSrvBaseConfig;

	/**
	 * Renseigne sur les aspects user gérés dans cet univers
	 * @param aspect
	 */
	hasAspect(aspect: EUserAspects): boolean {
		return (this.config.usersAspects && this.config.usersAspects.indexOf(aspect) > -1) ? true : false;
	}
}

/**
 * Server pour:
 * - obtenir les informations sur le user actuellement connecté (ou anonyme si autorisé),
 * - écouter les changements relatifs au user actuellement connecté,
 * - deconnecter le user connecté.
 *
 * Les fonctions de login, chgt ou perte de mot de passe, etc sont gérés par ailleurs (UserSelfSrv).
 */
export class AuthSrv {

	public readonly listeners = new EventsMgr<IAuthEvents>();

	/**
	 * undefined : jamais encore défini (chargement de la page en cours),
	 * null : aucun user actifs.
	 */
	protected user?: JUser | null;
	protected userStamp: string;

	protected currentUserUrl: IEndPoint;

	/** Partage de l'auth inter-onglets. */
	protected authBroadcast?: BroadcastChannel;
	protected broadcastsPending = 0;

	constructor(public readonly config: OAuthSrvConfig, universe: BasicUniverse) {
		//on indique au registre si on est authentifié ou pas : modifie les roles par défaut.
		const noAuth = (universe.reg.env as IAuthenticatedEnv).noAuthentication = this.config.noAuthentication || false;
		(universe.reg.env as IAuthenticatedEnv).remoteAuthentications = this.config.remoteAuthentications;
		(universe.reg.env as IAuthenticatedEnv).embeddedAuthentication = this.config.embeddedAuthentication;
		if (!noAuth) {
			//Gestion du dispatch à travers tous les onglets qui partagent cette authentification.
			this.authBroadcast = new BroadcastChannel("auth:" + (this.config.rootUrl || "/"));
			this.authBroadcast.onmessage = async (ev: MessageEvent) => {
				if (ev.data === "loggedUserChanged") {
					try {
						this.broadcastsPending++; //On bloque la réentrance du broadcast.
						await this.fetchUser();
					} finally {
						this.broadcastsPending--;
					}
				}
			};
		}
	}

	/** Permet d'écouter la fermeture de la session user. */
	connectToWs(ws: IWsExecFrame) {
		//Listener singleton même si plusieurs appels à connectToWs() car univers différents mais même authSrv.
		if (!this._lstn) this._lstn = (m: JSessionMsg) => {
			if (m.evt === "session:logout" || m.evt === "session:timeout") {
				this.onDisconnect();
			}
		};
		ws.msgListeners.on("session", this._lstn);
	}

	protected _lstn?: (m: JSessionMsg) => void

	/** Retourne le compte du user actuellement chargé ou du user anonyme(sans check auprès du server). */
	get currentAccount(): string {
		if (this.user) return this.user.account;
		if (this.config.anonymousUser) return this.config.anonymousUser.account;
		return "";
	}

	/** Retourne le user actuellement chargé ou le user anonyme (sans check auprès du server). */
	get currentUser(): JUser | null {return this.user || this.config.anonymousUser}

	/** Retourne le user actuellement chargé (sans check auprès du server). */
	get currentAuthenticatedUser(): JUser | null {return this.user}

	/** Retourne le user anonyme si il est autorisé (qui n'est pas forcément le user actuellement connecté). */
	get anonymousUser(): JUser | null {return this.config.anonymousUser}

	/** Le user courant si il définit est-il superAdmin ? */
	get isSuperAdmin(): boolean {return this.user && this.user.isSuperAdmin}

	/** Charge ou controle auprès du server les propriétés du user authentifié ou retourne le user anonyme si autorisé, null sinon. */
	async fetchUser(): Promise<JUser> {
		if (!this.config.noAuthentication) {
			if (!this.currentUserUrl) {
				this.currentUserUrl = this.config.adminUsersUrl.resolve("?cdaction=CurrentUser");
				if (isEndPointErrorHookable(this.currentUserUrl)) {
					//on tue une éventuelle réentrance : détection générique 403 -> fetchUser().
					const sub = this.currentUserUrl.errorHook;
					this.currentUserUrl.setErrorHook(sub ? {
						onEndPointError: function (res: IResponse) {
							if (res.status === 403) return;
							sub.onEndPointError(res);
						}
					} : null);
				}
			}
			const r = await this.currentUserUrl.fetch(null, 'text');
			if (r.ok) {
				await this.setCurrentUser(JSON.parse(r.asText) as JUser, r.asText);
			} else {
				//Anomalie, pas ou plus de user courant
				await this.setCurrentUser(null, null);
			}
		} else if (this.config.anonymousUser) {
			await this.setCurrentUser(this.config.anonymousUser, "");
		} else throw Error("Error config : no authentication and no anonymous user defined");
		return this.currentUser;
	}

	/** Modifie les propriétés du user courant authentifié */
	async updateCurrentUser(props?: Dict<any>): Promise<Boolean> {
		if (!this.config.noAuthentication) {
			const initReq = {} as RequestInit;
			initReq.method = 'POST';
			const body = initReq.body = new FormData();
			body.append("userProps", CDM.stringify(props));
			initReq.headers = {"ScCsrf": "1"};
			const r = await this.config.adminUsersUrl.fetchJson<any>("?cdaction=UpdateCurrentUser", initReq);
			if (r.user as JUser) {
				await this.setCurrentUser(r.user, JSON.stringify(r.user));
				this.listeners.emit("userUpdated", this.currentUser.account, this.currentUser);
				return true;
			}
		} else throw Error("Error config : no authentication defined");
		return false;
	}

	/** Récupère un tableau des groupes jusqu'au user lui même. */
	async fetchFlattenedGroups(): Promise<string[]> {
		//TODO cache de quelques minutes...
		const u = await this.config.adminUsersUrl.fetchJson<JUser>("?cdaction=CurrentUser&fields=flattenedGroups");
		return u.flattenedGroups || [u.account];
	}

	/** Un evènement a provoqué la déconnexion du user. */
	onDisconnect() {
		this.setCurrentUser(null, null);
	}

	protected setCurrentUser(u: JUser, stamp: string): Promise<void> | null {
		if (this.userStamp !== stamp) {
			//Le user a bien changé.
			if (this.broadcastsPending === 0 && this.user !== undefined) {
				//on n'est pas dans une mise à jour suite à un broadcast et ce n'est pas le 1er chargement de ce contexte
				// => on notifie les autres onglets
				this.authBroadcast?.postMessage("loggedUserChanged");
			}
			const old = this.currentUser;
			this.user = u;
			this.userStamp = stamp;
			return this.listeners.emitAsync('loggedUserChanged', old, u);
		}
	}
}

export interface OAuthSrvConfig {

	/** Propriétés du user anonyme si autorisé. */
	anonymousUser?: JUser;

	/** True si l'environnement ne permet aucune auth (seul l'anonymous est autorisé) : version desktop / vue dépot "pur" public... */
	noAuthentication?: boolean;

	/** Déclare les authentifications déportées sur un système tiers (SAML, ...) */
	remoteAuthentications?: IRemoteAuth<any>[];

	/**
	 * Si false : pas d'authentification embeddée possible
	 * Si true : authentification embeddée possible avec le nom par défaut, si besoin de le nommer
	 * La string fournit le nom de cette authentificqation embeddée
	 */
	embeddedAuthentication?: boolean | string

	/** Service pour obtenir les propriétés du user connecté (accès authentifié). */
	adminUsersUrl?: IEndPoint;

	/**
	 * Url racine du portail permttant d'extraire le path correspondant au scope du cookie d'auth.
	 * Permet de gérer le broadcast du chgt de user sur tous les onglets ouverts d'un navigateur.
	 */
	rootUrl?: string;
}


export function configAuthSrv(authenticatedExecFrameUrl: IEndPoint, publicExecFrameUrl: IEndPoint, config?: OAuthSrvConfig): OAuthSrvConfig {
	if (!config) config = {} as OAuthSrvConfig;
	if (!config.adminUsersUrl) config.adminUsersUrl = authenticatedExecFrameUrl.resolve("u/adminUsers");
	if (config.anonymousUser) config.anonymousUser.isAnonymous = true; //on s'assure que le user anonyme à la propriété isAnonymous=true.
	config.remoteAuthentications?.forEach(entry => entry.configAuthSrv ? entry.configAuthSrv(authenticatedExecFrameUrl, publicExecFrameUrl) : null);
	return config;
}


/**
 * Serveur d'accès aux users (lecture, écriture, recherche...).
 */
export class UsersSrv extends UserSrvBase {

	protected cache: Map<string, JUser>;

	/** Stack d'account en attente d'être chargé via une requete unique. */
	protected fetchingReq: Map<string, FetchingUser> = null;

	auth: AuthSrv;

	constructor(public readonly config: OUsersSrvConfig) {
		super();
	}

	/** Permet de notifier cet authServer d'un acte de login / logout réussit. */
	connectToAuth(auth: AuthSrv) {
		this.auth = auth;
		this.auth.listeners.on("userUpdated", (account: string) => {this.invalidateCacheFor(account)});
	}

	/**
	 * Demande les propriétés d'un user.
	 * @see getUserBatch()
	 */
	async getUser(nickOrAccount: string): Promise<JUser> {
		const cache = this.getFromCache(nickOrAccount);
		if (cache) return cache;
		const resp = await this.config.adminUserUrl.fetchJson<{ user: JUser }>(IO.qs("cdaction", "Display", "param", nickOrAccount));
		if (resp.user) this.enrichCache(nickOrAccount, resp.user);
		return resp.user;
	}

	/**
	 * Demande les propriétés d'un user en sachant qu'il est probable que d'autres demandes
	 * du même ou d'un autre user soient effectuées dans le même cycle de traitement JS :
	 * les demandes sont empilées localement avant d'envoyer une seule requête au serveur.
	 *
	 * Les utilisateurs inconnus sont préservés (propriété "isUnknown:true")
	 */
	async getUserBatch(nickOrAccount: string): Promise<JUser> {
		const cache = this.getFromCache(nickOrAccount);
		if (cache) return cache;
		if (!this.fetchingReq) {
			this.fetchingReq = new Map();
			Promise.resolve().then(async () => {
				//On attend la fin du traitement
				const tasks = this.fetchingReq;
				this.fetchingReq = null;
				try {
					const userMap = await this.getUserMap(Array.from(tasks.keys()));
					for (const [k, v] of tasks) {
						let user = userMap[k] || {account: k, isUnknown: true};
						if (user) this.enrichCache(k, user);
						v.resolver(user);
					}
				} catch (e) {
					for (const v of tasks.values()) {
						v.rejecter(e);
					}
				}
			});
		}
		let fetching = this.fetchingReq.get(nickOrAccount);
		if (!fetching) {
			fetching = new FetchingUser();
			this.fetchingReq.set(nickOrAccount, fetching);
		}
		return fetching.promise;
	}

	/** Retourne une map dont les keys sont les nickOrAccount passés en paramètre
	 *	@params addFields, removeFields : permet d'ajouter/supprimer des propriétés parmi celles autorisées/déja présentes
	 */
	async getUserMap(nicksOrAccounts: string[], addFields?: ('flattenedGroups')[], removeFields?: string[]): Promise<Dict<JUser>> {
		const fields: string[] = addFields || (removeFields ? [] : null);
		if (removeFields)
			fields.concat(removeFields.map(entry => `-${entry}`))
		return this.config.adminUserUrl.fetchJson<Dict<JUser>>(IO.qs("cdaction", "DisplaySet", "param", nicksOrAccounts.join("\t"), "fields", fields));
	}

	/**
	 * Retourne un tableau de JUser correspondant aux nicksOrAccounts passés en entrée.
	 * Attention :
	 * - ordre du tableau d'entrée non respecté,
	 * - si le tableau d'entrée contient 2 fois le même nickOrAccount, la taille du tableau retourné sera différente de celle de l'entrée.
	 */
	async getUserSet(nicksOrAccounts: string[], preserveUnknown: boolean = false): Promise<JUser[]> {
		const resp = await this.getUserMap(nicksOrAccounts);
		const users: JUser[] = [];
		for (const userAccount in resp) {
			if (resp[userAccount])
				users.push(resp[userAccount]);
			else if (preserveUnknown)
				users.push({account: userAccount, isUnknown: true});
		}
		return users;
	}

	/**
	 * Retourne une liste d'utilisateurs
	 * @param firstChars
	 * @param filterType
	 * @param filterHidden
	 * @param filterGroupsMembers
	 * @param filterRoles
	 * @param maxResults
	 * @params addFields, removeFields : permet d'ajouter/supprimer des propriétés parmi celles autorisées/déja présentes
	 */
	async list(firstChars?: string, filterType?: EUserType, includeIsHidden?: boolean | null, filterGroupsMembers?: string[], maxResults?: number, fieldMatchRegExp?: RegExp, fieldMatchList?: string, addFields?: ('flattenedGroups')[], removeFields?: string[], filterRoles?: string[], filterDisabled?: EUserDisabledFilters): Promise<JUsersSet> {
		const fields: string[] = addFields || (removeFields ? [] : null);
		if (removeFields)
			fields.concat(removeFields.map(entry => `-${entry}`))

		const params: any = {};
		if (firstChars) params.firstChars = firstChars;
		if (filterType) params.filterType = filterType;
		if (includeIsHidden != null) params.filterHidden = includeIsHidden;
		if (filterGroupsMembers?.length) params.filterGroupsMembers = filterGroupsMembers;
		if (filterRoles?.length) params.filterRoles = filterRoles;
		if (filterDisabled) params.filterDisabled = filterDisabled;
		if (maxResults) params.maxResults = maxResults;
		if (fieldMatchRegExp) {
			let regExpTxt = "";
			if (fieldMatchRegExp.flags) regExpTxt += "(?" + fieldMatchRegExp.flags + ")";
			regExpTxt += fieldMatchRegExp.source;
			params.fieldMatchRegExp = regExpTxt;
		}
		if (fieldMatchList) params.fieldsContainsList = fieldMatchList;
		return this.config.adminUserUrl.fetchJson<JUsersSet>(IO.qs("cdaction", "List", "options", CDM.stringify(params), "fields", fields));
	}


	/**
	 * Suppression d'utilisateurs
	 */
	async dropUsers(nicksOrAccounts: string[]): Promise<void> {
		const initReq: RequestInit = {
			method: 'POST',
			headers: {"ScCsrf": "1",},
			body: new FormData(),
		};
		(initReq.body as FormData).append("param", nicksOrAccounts.join("\t"));
		return this.config.adminUserUrl.fetchVoid(IO.qs("cdaction", "DropUser"), initReq);
	}

	/**
	 * Suppression de groupes
	 */
	async dropGroups(nicksOrAccounts: string[]): Promise<void> {
		const initReq: RequestInit = {
			method: 'POST',
			headers: {"ScCsrf": "1",},
			body: new FormData(),
		};
		(initReq.body as FormData).append("param", nicksOrAccounts.join("\t"));
		return this.config.adminUserUrl.fetchVoid(IO.qs("cdaction", "DropGroup"), initReq);
	}

	/**
	 * Résolution de rôles à partir d'une liste de groupes
	 */
	async resolveRoles(groupesNicksOrAccounts: string[]): Promise<[string]> {
		const initReq: RequestInit = {method: 'POST', body: new FormData()};
		(initReq.body as FormData).append("param", groupesNicksOrAccounts.join("\t"));
		return this.config.adminUserUrl.fetchJson(IO.qs("cdaction", "ResolveRoles"), initReq);
	}


	/**
	 * Création d'utilisateur / groupe
	 * 		ATTENTION : utiliser le service userSelf.createUser, userSelf.createUGroup
	 */
	createUser(): void {throw "Use userSelf svc"}

	createGroup(): void {throw "Use userSelf svc"}

	/**
	 * Actualisation d'utilisateur / groupe
	 * 		ATTENTION : utiliser le service userSelf.updateUser, userSelf.updateGroup
	 */
	updateUser(): void {throw "Use userSelf svc"}

	updateGroup(): void {throw "Use userSelf svc"}

	protected getFromCache(nickOrAccount: string): JUser | null | undefined {
		return this.cache ? this.cache.get(nickOrAccount) : undefined;
	}

	/** Lors d'une détection d'un chgt des props d'un user. */
	invalidateCacheFor(nickOrAccount: string) {
		if (!this.cache) return;
		const user = this.cache.get(nickOrAccount);
		if (user) {
			this.cache.delete(user.account);
			if (user.nickNames) for (let i = 0; i < user.nickNames.length; i++) this.cache.delete(user.nickNames[i]);
		} else if (user === null) {
			this.cache.delete(nickOrAccount); //cache pas trouvé
		}
	}

	/** NE PAS UTILISER pour une détection d'un chgt des props d'un user. */
	protected enrichCache(asked: string, user: JUser) {
		if (this.cache == null) {
			this.cache = new Map();
			setTimeout(() => {this.cache = null}, 10000); //cleanup du cache toutes les 10 secondes.
		}
		if (user) {
			this.cache.set(user.account, user);
			if (user.nickNames) for (let i = 0; i < user.nickNames.length; i++) this.cache.set(user.nickNames[i], user);
			this.cache.set(asked, null); //cache pas trouvé
		}
	}

}

export enum EUserAspects {
	'groupable' = 'groupable',
	'rolable' = 'rolable',
	'updatable' = 'updatable',
	'oneNickName' = 'oneNickName',
	'hideable' = 'hideable',
	'enabledEndDt' = 'enabledEndDt',
	'disabledEndDt' = 'disabledEndDt',
}

export interface OUsersSrvConfig extends OUserSrvBaseConfig {
	adminUserUrl?: IEndPoint
}


export function configUsersSrv(authenticatedExecFrameUrl: IEndPoint, config: OUsersSrvConfig, forAdmin: boolean): OUsersSrvConfig {
	if (!config) config = {} as OUsersSrvConfig;
	if (!config.adminUserUrl) config.adminUserUrl = authenticatedExecFrameUrl.resolve(forAdmin ? "u/adminUsers" : "u/useUsers");
	return config;
}

class FetchingUser {
	promise: Promise<JUser>;
	resolver: (user: JUser) => void;
	rejecter: (reason: any) => void;

	constructor() {
		this.promise = new Promise<JUser>((resolve, reject) => {
			this.resolver = resolve;
			this.rejecter = reject;
		})
	}
}


interface JSessionMsg {
	svc: 'session',
	evt: 'session:logout' | 'session:timeout'
}

/**
 *
 */
export namespace USER {

	export const anonymousLabel = "Utilisateurs non authentifiés";

	/**
	 * A utiliser par défaut pour afficher le libellé d'un user.
	 */
	export function getPrimaryName(user: JUserBase): string {
		if ((user as JUser).isAnonymous) return USER.anonymousLabel;
		if (user.userType === EUserType.group) return user.groupName || user.account;
		const nicks = user.nickNames;
		return nicks && nicks.length > 0 ? nicks[0] : user.account;
	}

	/**
	 * Nom détaillé complémentaire à #getPrimaryName().
	 * Retourne "" si pas d'information complémentaire.
	 */
	export function getLongName(user: JUserBase): string {
		if (user.userType === EUserType.group) return "";
		const fn = user.firstName;
		const ln = user.lastName;
		return fn && ln ? `${fn} ${ln}` : fn || ln || "";
	}

	/**
	 * Combine getPrimaryName() et getLongName()
	 */
	export function getFullDesc(user: JUserBase): string {
		const p = getPrimaryName(user);
		const n = getLongName(user);
		return n ? `${p} - ${n}` : p;
	}

	/**
	 * Retourne l'URL résolue de l'icône associée à cet utilisateur
	 */
	export function getIconUrl(user: JUser): string {
		return user.userType === EUserType.group ? "/@skin@/core/objects/group.svg" : "/@skin@/core/objects/user.svg";
	}
}